import React, { useMemo } from "react";
import { InputProps } from "./props";
import { DatePicker } from "./types/datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { css } from "styled-components";
import { InputSelect } from "./types/select";
import { Icon } from "@atoms/icon";
import { InputTextArea } from "./types/textarea";

export const Input = React.forwardRef<HTMLElement, InputProps>(
  function Component(
    { className, type, endAddorment, error, errorMessage, ...rest },
    ref
  ) {
    const InputElement: React.ReactElement = useMemo(() => {
      switch (type) {
        case "datepicker":
          return (
            <DatePicker ref={ref} className="input--field" {...(rest as any)} />
          );
        case "select":
          return (
            <InputSelect
              ref={ref}
              className="input--field"
              {...(rest as any)}
            />
          );
        case "text":
        case "number":
        case "email":
        case "tel":
        case "checkbox":
          return (
            <input
              ref={ref}
              type={type}
              className="input--field"
              {...(rest as any)}
            />
          );
        case "textarea":
          return (
            <InputTextArea
              ref={ref}
              className="input--field"
              {...(rest as any)}
            />
          );
        default:
          throw new Error(`Not implemented input type ${type}`);
      }
    }, [ref, type, rest]);

    return (
      <StyledInputContainer className={className} error={error}>
        <div className="input--container">
          {InputElement}
          {endAddorment && (
            <div className="input--addorment">
              <Icon name={endAddorment} />
            </div>
          )}
        </div>
        {error && errorMessage && (
          <span className="validation-error">{errorMessage}</span>
        )}
      </StyledInputContainer>
    );
  }
);

const StyledInputContainer = styled.div.attrs<{ error?: boolean }>((props) => ({
  error: props.error,
}))`
  .validation-error {
    color: #c53131;
    display: block;
    margin: 0.5rem 0;
    font-family: "Work Sans", Arial;
    font-size: 0.8em;
  }

  .input--container {
    display: flex;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #d9e0e7;
    align-items: center;
    justify-content: center;
    height: 100%;
    input,
    select,
    textarea {
      font-family: "Work Sans", Arial;
      font-size: 1em;
      padding: 0.8em 0.6em;
      width: 100%;
      color: #1d1d1d;
      border: none;
      box-sizing: border-box;
      outline: none;
      background: #fff;
      letter-spacing: 0.5px;
    }

    input {
      cursor: text;
    }

    select {
      cursor: pointer;
    }

    .input--field {
      width: 100%;
    }

    .react-datepicker-wrapper {
      width: 100%;
      flex: 1;
    }
  }
  .input--addorment {
    color: #d9e0e7;
    font-size: 1.5em;
    padding: 0 0.375em;
    .fh-icon {
      display: block;
    }
  }

  ${({ error }) =>
    error &&
    css`
      .input--container {
        border: 1px solid #c53131;
      }
      .input--addorment {
        color: #c53131;
      }
    `}
`;
