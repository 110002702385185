import { Icon } from "@atoms/icon";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

export type InputSelectProps = {
  id?: string;
  name?: string;
  defaultValue?: string | number;
  options: { value: string | number; label: string }[];
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  className?: string;
  title?: string;
  disabled?: boolean;
  nullable?: boolean;
};

export const InputSelect: FC<InputSelectProps> = React.forwardRef<
  HTMLSelectElement,
  InputSelectProps
>(function Component(
  { nullable, id, name, defaultValue, options, onChange, className, title, disabled },
  ref
) {
  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => onChange && onChange(e),
    [onChange]
  );

  return (
    <Container className={className}>
      <select
        ref={ref}
        title={title}
        id={id}
        name={name}
        onChange={handleChange}
        defaultValue={defaultValue ?? ""}
        disabled={disabled}
      >
        {nullable && title && (
          <option disabled value="">
            {title}
          </option>
        )}
        {options.map((x, i) => (
          <option key={`option_${i}`} value={x.value}>
            {x.label}
          </option>
        ))}
      </select>
      <Icon name="arrow-down" />
    </Container>
  );
});

const Container = styled.div`
  display: inline-block;
  position: relative;

  select {
    border: none;
    appearance: none;
    width: 100%;
  }
  .fh-icon {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 0.3em;
    color: #d9e0e7;
    pointer-events: none;
  }
`;
