import { Icon } from "@atoms/icon";
import { Price } from "@atoms/price";
import { Typography } from "@atoms/typography";
import { routes } from "@fhlib/routes";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC, useMemo } from "react";
import { useTranslation } from "next-i18next";
import styled from "styled-components";
import { SearchForm } from "@organisms/searcher";
import { useFHConfig } from "@templates/config";

export type PropertyCardProps = {
  id: number;
  slug: string;
  cover: string;
  price: number;
  guests: number;
  bathrooms: number;
  bedrooms: number;
  title: string;
  type?: string;
  searchFormData?: SearchForm;
};

export const PropertyCard: FC<PropertyCardProps> = ({
  id,
  slug,
  cover,
  price,
  guests,
  bathrooms,
  bedrooms,
  title,
  type,
  searchFormData,
}) => {
  const config = useFHConfig();
  const { t } = useTranslation("property");
  const router = useRouter();

  const sheetEndpoint = useMemo(
    () =>
      `${routes["homes-results"][router?.locale ?? "en"]}/${slug}` +
      (searchFormData && Object.keys(searchFormData).length > 0
        ? `?${new URLSearchParams({
            check_in: searchFormData.checkIn + "",
            check_out: searchFormData.checkOut + "",
            guests: searchFormData.guests + "",
          }).toString()}`
        : ""),
    [router, searchFormData, slug]
  );

  return (
    <Link href={sheetEndpoint} passHref legacyBehavior>
      <StyledContainer>
        <div className="property-header">
          <picture>
            <source
              srcSet={`${config.backUrl}/assets/${cover}?fit=cover&width=333&height=222&format=webp`}
              type="image/webp"
            />
            <img
              className="property-image"
              alt={`Cover ${title}`}
              src={`${config.backUrl}/assets/${cover}?fit=cover&width=333&height=222`}
              width="333"
              height="222"
              loading="lazy"
            />
          </picture>
          <Price className="property-price" amount={price} asInteger />
        </div>
        <div className="property-content">
          <Typography variant="title2">{title}</Typography>
          {type && <Typography variant="small">{type}</Typography>}
        </div>
        <div className="property-footer">
          <div>
            <Icon name="people" />
            {guests} {t("guest", { count: guests })}
          </div>
          <div>
            <Icon name="bathtub" />
            {bathrooms} {t("bathroom", { count: bathrooms })}
          </div>
          <div>
            <Icon name="door" />
            {bedrooms} {t("bedroom", { count: bedrooms })}
          </div>
        </div>
      </StyledContainer>
    </Link>
  );
};

const StyledContainer = styled.a`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 23rem;
  width: 100%;
  transition: transform 0.3s;
  background-color: #fff;
  text-decoration: none;
  border: 1px solid #ebebeb;

  .property-header {
    position: relative;
    overflow: hidden;

    .property-image {
      width: 100%;
      height: auto;
      display: block;
      transition: transform 0.3s;
    }

    .property-price {
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: rgba(253, 187, 48, 0.9);
      color: #fff;
      font-family: "Work Sans", Arial;
      padding: 0.3em 0.7em;
      font-size: 1.5em;
      transition: background-color 0.3s, padding 0.3s;
      border-top-left-radius: 0.3em;
    }
  }

  .property-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0.5em 1em;

    h2 {
      margin: 0.5em 0;
      font-size: 1.2em;
      flex: 1;
    }
  }

  .property-footer {
    padding: 0.8em 1em;
    display: flex;
    border-top: 1px solid #e0e0e0;
    gap: 0.5em;
    > div {
      flex: 1;
      color: #1d1d1d;
      display: flex;
      gap: 0.2em;
      align-items: center;
      justify-content: center;
      font-size: 0.8em;
      font-family: "Work Sans", Arial;
      white-space: nowrap;
      .fh-icon {
        color: #999ca9;
        font-size: 1.5em;
      }
    }
  }

  &:hover {
    transform: Scale(1.03);
    .property-image {
      transform: Scale(1.05);
    }
    .property-price {
      background-color: rgba(253, 187, 48, 1);
      padding: 0.3em 1em;
    }
  }
`;
