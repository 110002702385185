import styled from "styled-components";

export const InputTextArea = styled.textarea`
  display: block;
  width: 100%;
  border: none;
  resize: none;
  font-family: "Numas", Arial;
  font-size: 1em;
  padding: 0.5em;
  box-shadow: none;
`;
