import React, { FC, useMemo } from "react";
import { Trans, useTranslation } from "next-i18next";
import styled, { css } from "styled-components";
import { Button } from "../../atoms/button/button";
import { Input } from "../../atoms/input/input";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  useForm,
} from "react-hook-form";

export type SearchForm = {
  checkIn?: Date | string;
  checkOut?: Date | string;
  guests?: number;
};

export type SearcherProps = {
  sticky?: boolean;
  initialForm?: SearchForm;
  onSearch: (form: SearchForm) => void;
};

function InputCheckIn({
  field,
  fieldState,
  endDate,
}: {
  fieldState: ControllerFieldState;
  field: ControllerRenderProps<SearchForm, "checkIn">;
  endDate?: Date | string;
}) {
  const { t } = useTranslation("forms");
  const { onChange, value, ...rest } = field;
  const selected = useMemo(
    () => (typeof value === "string" ? new Date(value) : value),
    [value]
  );
  return (
    <Input
      placeholderText={t("arrival_date")}
      type="datepicker"
      endAddorment="calendar"
      className="form-check-in"
      title={t("arrival_date")}
      onChange={(value: any) => onChange({ target: { value } })}
      selected={selected}
      startDate={selected}
      error={!!fieldState.error}
      errorMessage={fieldState.error?.message}
      selectsStart
      endDate={typeof endDate === "string" ? new Date(endDate) : endDate}
      {...rest}
    />
  );
}

function InputCheckOut({
  field,
  fieldState,
  startDate,
}: {
  fieldState: ControllerFieldState;
  field: ControllerRenderProps<SearchForm, "checkOut">;
  startDate?: Date | string;
}) {
  const { t } = useTranslation("forms");
  const { onChange, value, ...rest } = field;
  const selected = useMemo(
    () => (typeof value === "string" ? new Date(value) : value),
    [value]
  );

  const checkInDate =
    typeof startDate === "string" ? new Date(startDate) : startDate;
  const minDate = checkInDate ?? new Date();

  return (
    <Input
      placeholderText={t("departure_date")}
      type="datepicker"
      endAddorment="calendar"
      className="form-check-out"
      title={t("departure_date")}
      onChange={(value: any) => onChange({ target: { value } })}
      selected={selected}
      endDate={selected}
      error={!!fieldState.error}
      errorMessage={fieldState.error?.message}
      minDate={minDate}
      startDate={checkInDate}
      selectsEnd
      {...rest}
    />
  );
}

export const Searcher: FC<SearcherProps> = ({
  sticky,
  initialForm,
  onSearch,
}) => {
  const { t } = useTranslation("forms");

  const guestsOptions = useMemo(
    () => [
      { value: "1", label: "1 " + t("guest", { count: 1 }) },
      { value: "2", label: "2 " + t("guest", { count: 2 }) },
      { value: "3", label: "3 " + t("guest", { count: 3 }) },
      { value: "4", label: "4 " + t("guest", { count: 4 }) },
      { value: "5", label: "5 " + t("guest", { count: 5 }) },
      { value: "6", label: "6 " + t("guest", { count: 6 }) },
      { value: "7", label: "7 " + t("guest", { count: 7 }) },
      { value: "8", label: "8 " + t("guest", { count: 8 }) },
      { value: "9", label: "9 " + t("guest", { count: 9 }) },
      { value: "10", label: "10 " + t("guest", { count: 10 }) },
      { value: "11", label: "11 " + t("guest", { count: 11 }) },
      { value: "12", label: "12 " + t("guest", { count: 12 }) },
      { value: "13", label: "13 " + t("guest", { count: 13 }) },
      { value: "14", label: "14 " + t("guest", { count: 14 }) },
      { value: "15", label: "15 " + t("guest", { count: 15 }) },
      { value: "16", label: "16 " + t("guest", { count: 16 }) },
      { value: "17", label: "17 " + t("guest", { count: 17 }) },
      { value: "18", label: "18 " + t("guest", { count: 18 }) },
      { value: "19", label: "19 " + t("guest", { count: 19 }) },
      { value: "20", label: "20 " + t("guest", { count: 20 }) },
    ],
    [t]
  );

  const { control, register, handleSubmit, watch } = useForm<SearchForm>();
  const [checkIn, checkOut] = watch(["checkIn", "checkOut"]);

  return (
    <StyledContainer sticky={sticky}>
      <StyledFormContainer onSubmit={handleSubmit(onSearch)}>
        <Controller
          name="checkIn"
          control={control}
          defaultValue={initialForm?.checkIn}
          render={({ field, fieldState }) => (
            <InputCheckIn
              endDate={checkOut}
              field={field}
              fieldState={fieldState}
            />
          )}
        />
        <Controller
          name="checkOut"
          control={control}
          defaultValue={initialForm?.checkOut}
          render={({ field, fieldState }) => (
            <InputCheckOut
              startDate={checkIn}
              field={field}
              fieldState={fieldState}
            />
          )}
        />
        <Input
          type="select"
          options={guestsOptions}
          className="form-guests"
          title={t("guest_plural")}
          defaultValue={initialForm?.guests}
          nullable
          {...register("guests")}
        />
        <Button className="btn-search" variant="primary" type="submit">
          {t("search")}
        </Button>
      </StyledFormContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div.attrs<{ sticky?: boolean }>((props) => ({
  sticky: props.sticky,
}))`
  box-shadow: 0 0.0625em 0.3125em rgb(0 0 0 / 20%);
  background-color: #fdbb30;
  padding: 0.625em;
  ${({ sticky }) =>
    sticky &&
    css`
      @media (min-width: 48em) {
        position: sticky;
        top: 3.75rem;
        z-index: 999;
      }
    `}
`;

const StyledFormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "check-in check-in"
    "nights guests"
    "search search";
  gap: 0.7em;

  .form-check-in {
    grid-area: check-in;
  }
  .form-nights {
    grid-area: nights;
  }
  .form-guests {
    grid-area: guests;
  }
  .btn-search {
    grid-area: search;
    cursor: pointer;
    font-size: 1.125em;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  @media (min-width: 48em) {
    gap: 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: "check-in nights guests search";
  }
`;
