import ExternalDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import es from "date-fns/locale/es";
import de from "date-fns/locale/de";
import usePortal from "@hooks/use-portal";
import { createPortal } from "react-dom";
import React from "react";
import { useRouter } from "next/router";

registerLocale("es", es);
registerLocale("de", de);

function PopperContainer({ children }: React.PropsWithChildren<{}>) {
  const target = usePortal("datepickers");
  return target ? createPortal(children, target) : <>{children}</>;
}

const DatePicker = React.forwardRef<any, any>((props, ref) => {
  const router = useRouter();

  return (
    <StyledDatePicker
      ref={ref}
      autoComplete="off"
      popperContainer={PopperContainer}
      dateFormat="dd/MM/yyyy"
      locale={router?.locale ?? "en"}
      {...props}
    />
  );
});
DatePicker.displayName = "DatePicker";

export { DatePicker };

const StyledDatePicker = styled(ExternalDatePicker)`
  border: none;
  width: 100%;
`;
StyledDatePicker.displayName = "StyledDatePicker";
