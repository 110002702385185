import {
  PropertyCard,
  PropertyCardProps,
} from "@molecules/property-card/property-card";
import { SearchForm } from "@organisms/searcher";
import React, { FC } from "react";
import styled from "styled-components";

export type ProductsGridProps = {
  products: PropertyCardProps[];
  searchFormData?: SearchForm;
};

export const ProductsGrid: FC<ProductsGridProps> = ({
  products,
  searchFormData,
}) => {
  return (
    <Container>
      {products.map((x) => (
        <PropertyCard
          key={`property_${x.slug}`}
          searchFormData={searchFormData}
          {...x}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 23rem));
  place-items: center;
  gap: 1.25rem;
  align-items: center;
  justify-content: center;
`;
