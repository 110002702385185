import { FC, useMemo } from "react";

export type PriceProps = {
  className?: string;
  amount: number;
  asInteger?: boolean;
};

export const Price: FC<PriceProps> = ({ asInteger, className, amount }) => {
  const formatter = useMemo(() => {
    const options: Intl.NumberFormatOptions = {
      style: "currency",
      currency: "EUR",
      useGrouping: true,
    };

    if (asInteger) {
      options.minimumFractionDigits = 0;
      options.maximumFractionDigits = 0;
    }

    return new Intl.NumberFormat("de-DE", options);
  }, [asInteger]);

  const formatted = useMemo(() => formatter.format(amount), [
    amount,
    formatter,
  ]);
  return <span className={className}>{formatted}</span>;
};
